const routes = () => [
  // path: '/home/:join?/:invite_code?',携带参数方式
  /**
   * @descrition 携带参数方式
   * @params {String} 当前参数
   * @identification {?} ?为参数可选
   * @hideFooter hideFooter:true 不显示Footer
    * @path 路由
        @icon 图标
        @title 菜单名称 支持语言切换
        @component 组件地址，当它是group入口（二级/三级菜单入口）时，设置为空
        @exact 是否匹配子路由
        @link 有表示链接第三方链接
        @children: 二级菜单
   **/
  {
    path: '/home',
    component: () => import('../pages/home'),
    exact: true,
  },
  {
    path: '/download',
    component: () => import('../pages/download'),
    exact: true,
  },
]
export default routes
