import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
// import Media from 'react-media'
import './index.less'
import LanguageSelect from '../LanguageSelect'

import PassMenu from '../Menu'
const PassHeader = ({ className }) => {
  const history = useHistory()

  const handleHome = () => {
    history.push({
      pathname: '/home',
    })
  }

  useEffect(() => {

  }, [])
  return (
    <div id={classNames('pass_header', className)}>
      <div className="header_left">
        <div className="logo" onClick={handleHome}></div>
      </div>
      <div className="header_right">
        <PassMenu />
        <LanguageSelect />
      </div>
    </div>
  )
}

export default PassHeader
